<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>后台设置</span>
			</div>
			<el-tabs v-model="preUrl" @tab-click="tabClick">
				
				<el-tab-pane label="角色列表" name="role">
					<operate-search :isSuper="false" @search="search">
						<template #operateButtons>
							<el-button type="primary" size="small" @click="showForm('role')">新增角色</el-button>
						</template>
					</operate-search>
					<el-table ref="multipleTable" :data="roleList" border tooltip-effect="dark" class="mt-3">
						<el-table-column prop="name" label="角色名称" width="100">
						</el-table-column>
						<el-table-column prop="desc" label="描述">
						</el-table-column>
						<el-table-column prop="status" label="状态" width="100">
							<template slot-scope="scope">
								<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
									:active-value="1" :inactive-value="0" @change="changeStatus(scope.row)">
								</el-switch>
							</template>
						</el-table-column>
						<el-table-column prop="create_time" label="创建时间">
						</el-table-column>
						<el-table-column prop="operate" label="操作">
							<template slot-scope="scope">
								<el-button type="text" size="mini" @click="showForm('role', scope.row)">修改</el-button>
								<el-button type="text" size="mini" @click="openDrawer(scope.row)">配置权限
								</el-button>
								<el-button type="text" size="mini" @click="deleteItem(scope.row)">
									删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="权限列表" name="rule">
					<el-button type="primary" size="small" @click="showForm('rule')">新增权限</el-button>
					<el-tree :data="ruleList" :props="defaultProps" node-key="id" :expand-on-click-node="false"
						default-expand-all class="border-top mt-3 pt-2">
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<div>
								<span v-if="data.menu == 1" :class="data.icon ? data.icon : 'el-icon-star-on'"></span>
								<small v-else>{{data.method}}</small>
								<span class="mx-1">{{ data.name }}</span>
								<span class="badge" style="font-size: 100%;"
									:class="data.menu == 1 ? '' : 'badge-secondary'">{{data.menu == 1 ? data.frontpath : data.condition}}</span>
							</div>

							<span>
								<el-button :type="data.status ? 'primary' : 'info'" size="mini" plain
									@click="clickChangeStatus(data)">{{data.status ? '启用' : '禁用'}}</el-button>
								<el-button type="success" size="mini" plain @click="showForm('rule',data.id)">增加
								</el-button>
								<el-button type="warning" size="mini" plain @click="showForm('rule',data)">修改
								</el-button>
								<el-button type="danger" size="mini" plain @click="deleteItem(data)">删除</el-button>
							</span>
						</span>
					</el-tree>
				</el-tab-pane>
			</el-tabs>
			<el-pagination v-if="preUrl != 'rule'" @size-change="pageSizeChange" @current-change="pageCurrentChange"
				:current-page="pageData.current" :page-sizes="pageData.sizes" :page-size="pageData.size"
				layout="total, sizes, prev, pager, next, jumper" :total="pageData.total" class="mt-3">
			</el-pagination>
		</el-card>
		<el-dialog :title="formTitle" :visible.sync="formVisible">
			<!-- 添加/修改角色 -->
			<el-form v-if="preUrl == 'role'" :model="formData.role" :rules="roleRules" ref="roleRuleForm">
				<el-form-item label="角色名称" :label-width="formLabelWidth" prop="name">
					<el-input v-model="formData.role.name" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="描述" :label-width="formLabelWidth" prop="desc">
					<el-input v-model="formData.role.desc" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
			</el-form>
			<!-- 添加/修改规则 -->
			<el-form v-if="preUrl == 'rule'" :model="formData.rule" :rules="ruleRules" ref="ruleRuleForm">
				<el-form-item label="上级规则" :label-width="formLabelWidth" prop="rule_id">
					<el-select v-model="formData.rule.rule_id" placeholder="请选择上级规则" size="small">
						<el-option label="顶级规则" :value="0"></el-option>
						<el-option v-for="item in ruleOptions" :label="item | tree" :value="item.id" :key="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单/规则" :label-width="formLabelWidth" prop="menu">
					<el-radio-group v-model="formData.rule.menu" size="small">
						<el-radio :label="1" border>菜单</el-radio>
						<el-radio :label="0" border>规则</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="名称" :label-width="formLabelWidth" prop="name">
					<el-input v-model="formData.rule.name" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>

				<el-form-item label="图标" v-if="formData.rule.menu == 1" :label-width="formLabelWidth" prop="icon">
					<el-input v-model="formData.rule.icon" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="前端路由" v-if="formData.rule.menu == 1" :label-width="formLabelWidth"
					prop="frontpath">
					<el-input v-model="formData.rule.frontpath" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="路由别名" v-if="formData.rule.menu == 1 && formData.rule.rule_id > 0"
					:label-width="formLabelWidth" prop="desc">
					<el-input v-model="formData.rule.desc" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>

				<el-form-item label="控制器" v-if="formData.rule.menu == 0" :label-width="formLabelWidth" prop="condition">
					<el-input v-model="formData.rule.condition" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>

				<el-form-item label="请求方法" v-if="formData.rule.menu == 0" :label-width="formLabelWidth" prop="method">
					<el-select v-model="formData.rule.method" placeholder="请选择请求方法" size="small">
						<el-option label="GET" value="GET"></el-option>
						<el-option label="POST" value="POST"></el-option>
						<el-option label="PUT" value="PUT"></el-option>
						<el-option label="DELETE" value="DELETE"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态" :label-width="formLabelWidth" prop="status">
					<el-radio-group v-model="formData.rule.status" size="small">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
		<el-drawer title="配置权限" :visible.sync="drawer" :before-close="handleClose">
			<div style="position: absolute;left: 0;right: 0;bottom: 0;top: 52px;">
				<div style="position: absolute;top:0;bottom: 60px;left: 0;right: 0;overflow-y: auto;">
					<el-tree v-if="drawer" :data="rules" default-expand-all :default-checked-keys="rule_ids" show-checkbox
						node-key="id" check-strictly @check="checkEvent" :props="defaultProps">
					</el-tree>
				</div>
				<div class="d-flex align-items-center border-top pl-3"
					style="height: 60px;position: absolute;left: 0;right: 0;bottom: 0;">
					<el-button plain size="small" @click="drawer = false">取消</el-button>
					<el-button type="primary" size="small" @click="setRules">确定</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import operateSearch from "@/components/common/operate-search.vue"
	import common from "@/common/mixins/common.js"
	export default {
		components: {
			operateSearch
		},
		inject: ['layout'],
		mixins: [common],
		data() {
			return {
				preUrl: 'role',
				keyword: '',
				adminList: [],
				roleOptions: [],
				roleList: [],
				rules: [],
				rule_ids: [],
				ruleList: [],
				ruleOptions: [],
				formTitle: '添加角色',
				formVisible: false,
				formType: 'role',
				formData: {
					role: {
						name: '',
						desc: '',
						status: 1
					},
					rule: {
						rule_id: 0,
						name: "",
						icon: "",
						desc: "",
						frontpath: "",
						condition: "",
						menu: 1,
						method: "GET",
						status: 1
					}
				},
				formLabelWidth: '120px',
				formItemId: 0,
				adminRules: {},
				roleRules: {},
				ruleRules: {},
				defaultProps: {
					children: 'child',
					label: 'name'
				},
				drawer: false,
				drawerId: 0
			};
		},
		methods: {
			getListRes(e) {
				switch (this.preUrl) {
					case 'role':
						this.roleList = e.list
						this.rules = e.rules
						break;
					case 'rule':
						this.ruleList = e.list
						this.ruleOptions = e.rules
						break;
					default:
						break;
				}
			},
			getUrl() {
				switch (this.preUrl) {
					case 'role':
						return `/admin/${this.preUrl}/index/${this.pageData.current}?limit=${this.pageData.size}`
						break;
					case 'rule':
						return `/admin/${this.preUrl}/index/${this.pageData.current}`
						break;
					default:
						break;
				}
			},
			tabClick() {
				this.getList()
			},
			search(e) {
				this.keyword = e
				this.getList()
			},
			showForm(type, data = false) {
				this.formType = type
				this.formItemId = data ? data.id : 0
				switch (type) {
					case 'role':
						this.formTitle = data ? '修改角色' : '添加角色'
						this.formData.role = {
							name: data ? data.name : '',
							desc: data ? data.desc : '',
							status: data ? data.status : 1
						}
						break;
					case 'rule':
						let isnum = typeof data == 'number'
						let ruleId = isnum ? data : 0
						data = isnum ? false : data
						this.formTitle = data ? '修改规则' : '添加规则'
						this.formData.rule = {
							rule_id: data ? data.rule_id : 0,
							name: data ? data.name : '',
							icon: data ? data.icon : '',
							frontpath: data ? data.frontpath : '',
							condition: data ? data.condition : '',
							menu: data ? data.menu : 1,
							method: data ? data.method : 'GET',
							desc: data ? data.desc : '',
							status: data ? data.status : 1
						}
						if (isnum) this.formData.rule.rule_id = ruleId
						break;
					default:
						break;
				}
				this.formVisible = true
			},
			submit() {
				this.addOrEdit(this.formData[this.formType], this.formItemId)
				this.formVisible = false
			},
			openDrawer(data) {
				this.drawerId = data.id
				this.rule_ids = []
				data.rules.forEach(item => {
					this.rule_ids.push(item.id)
				})
				this.drawer = true
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			checkEvent(currentNode, nodes) {
				this.rule_ids = nodes.checkedKeys
			},
			setRules() {
				this.axios.post('/admin/role/setrules', {
						id: this.drawerId,
						rule_ids: this.rule_ids
					}, {
						token: true
					})
					.then((res) => {
						this.drawer = false
						this.$message({
							message: '配置成功',
							type: 'success'
						})
						this.getList()
					})
					.catch((err) => {
						console.log(err)
					})
			}
		}
	};
</script>

<style>
	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}
	
	.el-tree-node__content {
		padding: 20px 0;
	}
</style>
